import React from 'react'

const HeaderTitle = () => {
  return (
    <div className='header'>
        <h1>dolarBlack.com.ar</h1>
    </div>
  )
}

export default HeaderTitle