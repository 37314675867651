import React from 'react'

const Footer = () => {
  return (
    <div className='main-footer'>
        <p>
        Blackdolar.com ® es un sitio meramente informativo, y no brindan consejo, recomendación, asesoramiento o invitación de ningún tipo, ni de ninguna clase o naturaleza, para realizar actos y/u operaciones de cualquier tipo, clase o naturaleza. Las fuentes de información aquí citadas son de público acceso. Los cuadros mostrados en este sitio son elaborados sobre la base de los datos de público acceso que en cada caso se indica, y constituyen propiedad intelectual amparada por la Ley N°11.723. Los titulares de este sitio deslindan toda responsabilidad respecto de la posible falta de precisión y/o veracidad y/o exactitud y/o integridad y/o vigencia de los datos y/o de las fuentes de información de público acceso tenidos en consideración para la elaboración del contenido de este sitio. Los titulares de este sitio no garantizan la precisión y/o veracidad y/o exactitud y/o integridad y/o vigencia de los datos mostrados en este sitio. Los titulares de este sitio deslindan toda responsabilidad respecto del uso que puedan llegar a dar a la información y/o a los datos incluídos en el contenido de este sitio quienes accedan a este último. Los titulares de este sitio no se responabilizan por los eventuales daños patrimoniales y/o perjuicios que pudieren resultar de decisiones adoptadas sobre la base de los datos mostrados en este sitio por quienes accedan a este último. Los titulares de este sitio no mantienen ni poseen ningún tipo de acuerdo, asociación, alianza o vínculo con los anunciantes que publicitan sus productos y/o servicios en este sitio más que la locación de espacios publicitarios. Los titulares de este sitio no se responsabilizan respecto de la precisión y/o veracidad y/o exactitud y/o integridad y/o vigencia de los contenidos de las piezas publicitarias o banners, por lo que tales contenidos son de exclusiva responsabilidad de los respectivos anunciantes.
        </p>
    </div>
  )
}

export default Footer